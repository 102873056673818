/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */
export default function baseEnv(baseApi, vcpApi, emailJs) {
    return {
        route: {
            baseRoute: '/', // Fixes issue with Github Pages
        },
        api: {
            demo: `${baseApi}/api`,
            strapi: `${baseApi}/:endpoint`,
            widget: `${baseApi}/widget`,
            errorExample: 'https://httpstat.us/520',
            subscribe: `${vcpApi}/api/roche/subscription/v3`,
            contactForm: `${emailJs}`,
            collections: `${vcpApi}/api/tertiary-collections/:endpoint`,
            recipeSearch: `${vcpApi}/search/:endpoint`,
            zoomInfo: `${vcpApi}/api/zoom-info/:endpoint`
        },
        isProduction: true,
        isDevelopment: false,
        isTesting: false,
    };
}
