import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import BlogModel from './models/blog/BlogModel';

export default class WidgetsEffect {
    public static async requestBlogs(): Promise<BlogModel | HttpErrorResponseModel> {
        const endpoint: string = environment.api.strapi.replace(':endpoint', 'blogs');

        return EffectUtility.getToModel(BlogModel, endpoint);
    }

    public static async requestBlog(slug: string): Promise<BlogModel | HttpErrorResponseModel> {
        const endpoint: string = environment.api.strapi.replace(':endpoint', `blogs?slug=${slug}`);

        return EffectUtility.getToModel(BlogModel, endpoint);
    }
}
