import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import RequestingReducer from './requesting/RequestingReducer';
import ErrorReducer from './error/ErrorReducer';
import StartupReducer from './startup/StartupReducer';
import BlogReducer from './blog/BlogReducer';
import TeamReducer from './team/TeamReducer';
import HomeReducer from './home/HomeReducer';
import TrendsReducer from './trends/TrendsReducer';
import VideoGalleryReducer from './video-gallery/VideoGalleryReducer';
import ImageGalleryReducer from './image-gallery/ImageGalleryReducer';

export default (history) => {
    const reducerMap = {
        error: ErrorReducer.reducer,
        requesting: RequestingReducer.reducer,
        router: connectRouter(history),
        app: new StartupReducer().reducer,
        home: new HomeReducer().reducer,
        blog: new BlogReducer().reducer,
        team: new TeamReducer().reducer,
        trends: new TrendsReducer().reducer,
        videos: new VideoGalleryReducer().reducer,
        images: new ImageGalleryReducer().reducer,
    };

    return combineReducers(reducerMap);
};
