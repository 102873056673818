import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import VideoModel from './models/video/VideoModel';

export default class VideoGalleryEffect {
    public static async requestVideos(): Promise<VideoModel | HttpErrorResponseModel> {
        const endpoint: string = environment.api.strapi.replace(':endpoint', 'videos');

        return EffectUtility.getToModel(VideoModel, endpoint);
    }
}
