import TagManager from 'react-gtm-module';

export const initGA = (trackingID) => {
    TagManager.initialize({ gtmId: trackingID });
};

export const PageView = () => {
    //
};

/**
 * Event - Add custom tracking event.
 */
export const Event = (event, data) => {
    console.log('custom_event', event, data);

    TagManager.dataLayer({
        dataLayer: {
            event: event,
            ...data
        },
        dataLayerName: 'dataLayer'
    });
};
