import BaseReducer from '../../utilities/BaseReducer';
import IAction from '../../models/IAction';
import InitialVideoGalleryState from './models/InitialVideoGalleryState';
import VideoModel from './models/video/VideoModel';
import VideoGalleryAction from './VideoGalleryAction';

export default class VideoGalleryReducer extends BaseReducer {
    public readonly initialState: InitialVideoGalleryState = {
        records: [],
    };

    public [VideoGalleryAction.REQUEST_VIDEOS_FINISHED](state: InitialVideoGalleryState, action: IAction<VideoModel>): InitialVideoGalleryState {
        return {
            ...state,
            records: action.payload!
        };
    }
}
