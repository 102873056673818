import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './NavView.scss';
import { NavLink } from 'react-router-dom';
import VimaxHalfLogo from '../../../images/navigation/vimax-half-logo.png';
import VimaxFullLogo from '../../../images/navigation/vimax-full-logo.png';
import Hamburger from './hamburger';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Calendly from "../calendly";


const links = [
    {
        type: 'internal',
        newWindow: true,
        path: '/about-us',
        textLg: 'ABOUT US',
        textSm: 'our key ingredients',
        indent: 0
    },
    {
        type: 'internal',
        newWindow: true,
        path: '/capabilities',
        textLg: 'CAPABILITIES',
        textSm: 'a taste of what we do',
        indent: 0
    },
    {
        type: 'external',
        newWindow: false,
        path: '/?location=our-latest',
        textLg: 'WORK',
        textSm: 'the flavor we bring',
        indent: 0
    },
    {
        type: 'internal',
        newWindow: false,
        path: '/photos',
        textLg: 'PHOTOGRAPHY',
        textSm: 'whet your appetite',
        indent: 0
    },
    {
        type: 'internal',
        newWindow: false,
        path: '/videos',
        textLg: 'VIDEO',
        textSm: 'feast your eyes',
        indent: 0
    },
    {
        type: 'internal',
        newWindow: false,
        path: '/blogs',
        textLg: 'BLOG',
        textSm: 'free brain food',
        indent: 0
    },
    {
        type: 'internal',
        newWindow: false,
        path: '/sparkshoppe',
        textLg: 'Vimax Media x SparkShoppe',
        textSm: '',
        indent: 0
    },
    {
        type: 'internal',
        newWindow: true,
        path: '/contact',
        textLg: 'CONTACT US',
        textSm: 'and see how we mix',
        indent: 0
    }
];

export interface navProps {
    transparent: boolean,
}

export interface linkRef {
    current: HTMLElement[]
}

const NavView = (props: navProps) => {
    gsap.registerPlugin(ScrollTrigger);

    const [navState, setNavState] = useState(false);
    const [minified, setMinified] = useState(false);

    // Logo setup
    const logoConfig: GSAPTimelineVars = { paused: true };
    const logoTl: GSAPTimeline = gsap.timeline(logoConfig);
    const navRef = useRef<HTMLDivElement>(null);
    const fullLogoRef = useRef<HTMLImageElement>(null);
    const halfLogoRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        logoTl
            .fromTo(
                navRef.current,
                {
                    height: '136px',
                },
                {
                    height: 'auto',
                    duration: .1
                }, 0)
            .fromTo(
                fullLogoRef.current,
                {
                    autoAlpha: 1,
                    width: '100%',
                },
                {
                    autoAlpha: 0,
                    duration: 0.1
                }, 0)
            .fromTo(
                halfLogoRef.current,
                {
                    autoAlpha: 0,
                    width: '115px',
                    transform: 'translate(-50%, -50%)',
                    scale: 0.2,
                },
                {
                    autoAlpha: 1,
                    scale: 1,
                    transform: 'translate(-50%, 25%)',
                    duration: 0.1
                }, 0);

        ScrollTrigger.create({
            trigger: navRef.current,
            start: 'top+=100 top',
            animation: logoTl,
            toggleActions: 'play none none reset',
            onEnter: () => setMinified(true),
            onLeaveBack: () => setMinified(false)
        });

    }, []);

    const transparentNavStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        boxShadow: 'unset',
        backgroundColor: 'rgba(255, 255, 255, .5)',
    };

    const solidNavStyle = {
    };

    return (
        <div className={'nav-desktop-wrapper relative z-40'}>
            <div className={'bar flex align-items-center justify-between'}
                 style={props.transparent ? transparentNavStyle : solidNavStyle} ref={navRef}>
                <Hamburger
                    onClick={() => setNavState(!navState)}
                    open={navState} width={'70px'}
                    color={'#3A9662'}
                    activeColor={'#3A9662'}
                />
                <NavLink to={'/'} id={'logo'}>
                    <img className={'half'} ref={halfLogoRef} src={VimaxHalfLogo} />
                    <img className={'full'} ref={fullLogoRef} src={VimaxFullLogo} />
                </NavLink>
                {/*<NavLink to={'/contact'} className={'hidden md:inline bg-vm-green white text-2xl font-semibold cursor-pointer mr-7 p-3 rounded-full'}>{"Let's Chat"}</NavLink>*/}
                <Calendly />
            </div>
            {
                props.transparent ? <TransparentMenu links={links} setNavState={setNavState} navState={navState} minified={minified}/> : <SolidMenu links={links} setNavState={setNavState} navState={navState} minified={false}/>
            }
        </div>
    );
};

NavView.propTypes = {
    transparent: PropTypes.bool.isRequired,
};

export default NavView;

type IMenuProps = {
    links: {
        type: string;
        newWindow: boolean;
        path: string;
        textLg: string;
        textSm: string;
        indent: number;
    }[];
    setNavState: (state: boolean) => void;
    navState: boolean;
    minified: boolean;
}

export const SolidMenu = (props: IMenuProps) => {

    // Menu setup
    const tlConfig: GSAPTimelineVars = { paused: true, reversed: true };
    const timeline: GSAPTimeline = gsap.timeline(tlConfig);
    const [tl] = useState(timeline);

    const menuRef = useRef<HTMLDivElement>(null);

    const linkRef: linkRef = useRef([]);
    linkRef.current = [];
    const addToLinksRefs = (el: HTMLAnchorElement) => {
        if (el && !linkRef.current.includes(el)) {
            linkRef.current.push(el);
        }
    };

    useEffect(() => {
        tl
            .fromTo(
                menuRef.current,
                {
                    x: -100,
                    autoAlpha: 0
                },
                {
                    x: 0,
                    autoAlpha: 1,
                    duration: 0.1
                },
                0
            )
            .fromTo(
                linkRef.current,
                {
                    autoAlpha: 0
                },
                {
                    autoAlpha: 1,
                    stagger: 0.05
                },
                0
            )
    }, []);

    useEffect(() => {
        props.navState ? tl.play() : tl.reverse();
    }, [props.navState]);

    return (
        <div className={'menu flex flex-col z-50 p-12 w-auto absolute'} ref={menuRef}
             style={{ backgroundColor: '#707070' }}>
            {
                props.links.map((link, i) => {
                    if (link.type === 'internal') {
                        return <NavLink ref={addToLinksRefs} className={`ml-${link.indent}`} to={link.path} key={i}
                                 onClick={() => props.setNavState(false)}><strong>{link.textLg}</strong>
                            <span className={'ml-1'}>{link.textSm}</span></NavLink>
                    } else if(link.type === 'external'){
                        return <a ref={addToLinksRefs} key={i}
                                  className={`ml-${link.indent}`}
                                  target={`${link.newWindow ? '_blank' : ''}`} rel="noreferrer"
                                  href={link.path} onClick={() => props.setNavState(false)}><strong>{link.textLg}</strong>
                            <span className={'ml-1'}>{link.textSm}</span></a>
                    } else if (link.type === 'label') {
                        return <a ref={addToLinksRefs} key={i} className={'cursor-default'}><strong>{link.textLg}</strong><span className={'ml-1'}>{link.textSm}</span></a>
                    }
                })
            }
        </div>
    )
}

export const TransparentMenu = (props: IMenuProps) => {
    // Menu setup
    const tlConfig: GSAPTimelineVars = { paused: true, reversed: true };
    const timeline: GSAPTimeline = gsap.timeline(tlConfig);
    const [tl] = useState(timeline);

    const vertTlConfig: GSAPTimelineVars = { paused: false };
    const vertTimeline: GSAPTimeline = gsap.timeline(vertTlConfig);
    const [vertTl] = useState(vertTimeline);

    const menuRef = useRef<HTMLDivElement>(null);

    const linkRef: linkRef = useRef([]);
    linkRef.current = [];
    const addToLinksRefs = (el: HTMLAnchorElement) => {
        if (el && !linkRef.current.includes(el)) {
            linkRef.current.push(el);
        }
    };

    useEffect(() => {
        tl
            .fromTo(
                menuRef.current,
                {
                    x: -100,
                    autoAlpha: 0,
                },
                {
                    x: 0,
                    autoAlpha: 1,
                    duration: 0.35
                },
                0
            )
            .fromTo(
                linkRef.current,
                {
                    autoAlpha: 0
                },
                {
                    autoAlpha: 1,
                    stagger: 0.08
                },
                0
            )
    }, []);

    useEffect(() => {
        vertTl
            .to(
                menuRef.current,
                {
                    top: !props.minified ? '136px' : '90.33px',
                    duration: 0.5
                })
    }, [props.minified]);



    useEffect(() => {
        props.navState ? tl.play() : tl.reverse();
    }, [props.navState]);

    return (
        <div className={'menu flex flex-col z-50 p-12 w-auto fixed'} ref={menuRef}
             style={{ backgroundColor: '#707070' }}>
            {
                props.links.map((link, i) => {
                    if (link.type === 'internal') {
                        return <NavLink ref={addToLinksRefs} to={link.path} key={i}
                                        className={`ml-${link.indent}`}
                                        onClick={() => props.setNavState(false)}><strong>{link.textLg}</strong>
                            <span className={'ml-1'}>{link.textSm}</span></NavLink>
                    } else if (link.type === 'external') {
                        return <a ref={addToLinksRefs} className={`ml-${link.indent}`} key={i} target={`${link.newWindow ? '_blank' : ''}`} rel="noreferrer" href={link.path} onClick={() => props.setNavState(false)}><strong>{link.textLg}</strong>
                            <span className={'ml-1'}>{link.textSm}</span></a>
                    } else if (link.type === 'label') {
                        return <a ref={addToLinksRefs} key={i} className={'cursor-default'}><strong>{link.textLg}</strong><span className={'ml-1'}>{link.textSm}</span></a>
                    }
                })
            }
        </div>
    )
}
