import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import ImageModel from './models/image/ImageModel';

export default class ImageGalleryEffect {
    public static async requestImages(limit: number = 200): Promise<ImageModel | HttpErrorResponseModel> {
        const endpoint: string = environment.api.strapi.replace(':endpoint', 'images');

        // Add query parameters to the endpoint
        const queryParams = new URLSearchParams({
            _limit: limit.toString()
        });

        const endpointWithParams = `${endpoint}?${queryParams.toString()}`;

        return EffectUtility.getToModel(ImageModel, endpointWithParams);
    }
}
