import BaseReducer from '../../utilities/BaseReducer';
import IAction from '../../models/IAction';
import TrendsAction from './TrendsAction';
import InitialTrendState from './models/InitialTrendState';
import TrendReportModel from './models/trend/TrendReportModel';

export default class TrendsReducer extends BaseReducer {
    public readonly initialState: InitialTrendState = {
        records: [],
    };

    public [TrendsAction.REQUEST_TRENDS_REPORTS_FINISHED](state: InitialTrendState, action: IAction<TrendReportModel>): InitialTrendState {
        return {
            ...state,
            records: action.payload!
        };
    }
}
