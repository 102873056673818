import ActionUtility from '../../utilities/ActionUtility';
import { Dispatch, Store} from 'redux';
import TeamEffect from './TeamEffect';

export default class TeamAction {
    public static readonly REQUEST_TEAM: string = 'TeamAction.REQUEST_TEAM';
    public static readonly REQUEST_TEAM_FINISHED: string = 'TeamAction.REQUEST_TEAM_FINISHED';

    public static requestTeam(): any {
        return async (dispatch: Dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, TeamAction.REQUEST_TEAM, TeamEffect.requestTeam);
        };
    }
}
