import BaseReducer from '../../utilities/BaseReducer';
import IAction from '../../models/IAction';
import HomeAction from './HomeAction';
import InitialHomeState from './models/InitialHomeState';
import HomeModel from './models/home/HomeModel';
import { act } from '@testing-library/react';

export default class HomeReducer extends BaseReducer {
    public readonly initialState: InitialHomeState = {
        banner_desktop: {
            url: ''
        },
        banner_mobile: {
            url: ''
        }
    };

    public [HomeAction.REQUEST_HOME_FINISHED](state: InitialHomeState, action: IAction<HomeModel>): InitialHomeState {

        return {
            ...state,
            // @ts-ignore
            banner_desktop: { url: action.payload!.banner_desktop.url },
            // @ts-ignore
            banner_mobile: { url: action.payload!.banner_mobile.url }
        };
    }
}
