import BaseReducer from '../../utilities/BaseReducer';
import IAction from '../../models/IAction';
import InitialTeamState from './models/InitialTeamState';
import EmployeeModel from './models/employee/EmployeeModel';
import TeamAction from './TeamAction';

export default class TeamReducer extends BaseReducer {
    public readonly initialState: InitialTeamState = {
        records: [],
    };

    public [TeamAction.REQUEST_TEAM_FINISHED](state: InitialTeamState, action: IAction<EmployeeModel>): InitialTeamState {

        return {
            ...state,
            records: action.payload!
        };
    }
}
