import React, {useEffect, useMemo, useRef, useState} from 'react';
import {gsap} from "gsap";
import WhiteExit from '../../../images/general/white-exit.svg';
import BlackExit from '../../../images/general/black-exit.svg';
import {Event} from "../tracking";

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

const CalendlyView = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    // TL config
    const popupConfig: GSAPTimelineVars = {paused: true, reversed: true};
    const popupTl = useMemo(() => gsap.timeline(popupConfig), []);

    const popupRef = useRef<any>(null);


    useEffect(() => {
        popupTl
            .fromTo(popupRef.current,
                {
                    autoAlpha: 0,
                },
                {
                    autoAlpha: 1
                })
    }, []);

    return (
        <>
            <div className={'lets-chat fixed z-50 bottom-5 right-5 lg:right-0 lg:bottom-0 lg:relative bg-vm-green white p-3 lg:mr-5 rounded-full cursor-pointer z-50'} onClick={() => {
                popupTl.play()
                Event('tracked_click', {
                    data: `lets_chat`
                });
            }}>
                {
                    windowDimensions.width > 800 ? (
                        <h3 className={'text-2xl cursor-pointer pointer-events-none'} onClick={() => {
                            Event('tracked_click', {
                                data: `nav__calendly`
                            });
                        }}>{`Let's Chat!`}</h3>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className={'pointer-events-none'} height="48" width="48" style={{ transform: `scale(-1, 1)`, fill: '#fff'}}><path d="M4 44V7Q4 5.85 4.9 4.925Q5.8 4 7 4H41Q42.15 4 43.075 4.925Q44 5.85 44 7V33Q44 34.15 43.075 35.075Q42.15 36 41 36H12Z"/></svg>
                    )
                }

            </div>
            <div className={'fixed top-0 left-0 w-screen h-screen bg-vm-green flex items-center justify-center'} style={{zIndex: 99}} ref={popupRef}>
                <div className={'relative w-full lg:w-1/2 h-full lg:h-3/4 flex items-center justify-center'}>
                    <iframe src={'https://calendly.com/vimax-media'} className={'w-full h-full bg-vm-green'}/>
                    <img src={WhiteExit} className={'absolute -top-5 -right-5 cursor-pointer fill-black'} onClick={() => popupTl.reverse()}/>
                    <img src={BlackExit} className={'absolute bottom-5 left-5 cursor-pointer fill-black lg:hidden'} onClick={() => popupTl.reverse()}/>
                </div>
            </div>
        </>
    );
};

export default CalendlyView;
