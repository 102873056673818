import BaseReducer from '../../utilities/BaseReducer';
import BlogAction from './BlogAction';
import BlogModel from './models/blog/BlogModel';
import InitialBlogState from './models/InitialBlogState';
import IAction from '../../models/IAction';

export default class BlogReducer extends BaseReducer {
    public readonly initialState: InitialBlogState = {
        records: [],
        active: false,
    };

    public [BlogAction.REQUEST_BLOGS_FINISHED](state: InitialBlogState, action: IAction<BlogModel>): InitialBlogState {

        return {
            ...state,
            records: action.payload!
        };
    }

    public [BlogAction.REQUEST_BLOG_FINISHED](state: InitialBlogState, action: IAction<BlogModel>): InitialBlogState {
        return {
            ...state,
            active: action.payload! ? action.payload[0] : false,
        }
    }
}
