enum RouteEnum {
    Home = '/',
    Blogs = '/blogs',
    Blog = '/blogs/:slug',
    WhatWeDo = '/what-we-do',
    Capabilities = '/capabilities',
    AboutUs = '/about-us',
    Contact = '/contact',
    PrivacyPolicy = '/privacy-policy',
    Trends = '/trends',
    VideoGallery = '/videos',
    VideoGalleryLipari = '/lipari-video-gallery',
    ImageGallery = '/photos',
    Sparkshoppe = '/sparkshoppe',
    VimaxXDole = '/vimax-x-dole',
    VimaxXGE = '/vimax-x-ge',
    VimaxXBB = '/vimax-x-brookshire-brothers',
    VimaxXCoke = '/vimax-x-coke',
    VimaxXWholly = '/vimax-x-wholly',
    VimaxXHerdez = '/vimax-x-herdez',
    VimaxXVallarta = '/vimax-x-vallarta',
    VimaxXCokeTwo = '/vimax-x-coca-cola',
    FeaturesAndBenefits = '/features-and-benefits',
    Pricing = '/pricing',
    Collection = '/collection/:id',
    ZoomInfoLP = '/introduction/:override_version?',
    VimaxXFiorucci = '/vimax-x-fiorucci',
    VimaxXNorthfork = '/vimax-x-northfork',
    Testimonials = '/testimonials',
    CollectionMarketing = '/custom-collection/:id',
    VideoGalleryHoliday = '/holiday-videos',
    VideoGalleryQ1 = '/spring-videos'
}

export default RouteEnum;
