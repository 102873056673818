import ActionUtility from '../../utilities/ActionUtility';
import { Dispatch, Store} from 'redux';
import HomeEffect from './HomeEffect';

export default class HomeAction {
    public static readonly REQUEST_HOME: string = 'HomeAction.REQUEST_HOME';
    public static readonly REQUEST_HOME_FINISHED: string = 'HomeAction.REQUEST_HOME_FINISHED';

    public static requestHome(): any {
        return async (dispatch: Dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, HomeAction.REQUEST_HOME, HomeEffect.requestHome);
        };
    }
}
