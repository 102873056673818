import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample

 */

export default class SubscribeBarResponseModel extends BaseModel {
    /*
     * Client-Side properties (Not from API)
     */
    success = '';
    subscription_id = '';
    // noneApiProperties = null;

    constructor(data) {
        super();

        this.update(data);
    }
}
