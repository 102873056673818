import { BaseModel } from 'sjs-base-model';

export default class ImageModel extends BaseModel {

    public readonly image: { url: string } = { url: ''};
    public readonly preview_image: { url: string } = { url: ''};
    public readonly name: string = '';

    constructor(data: Partial<ImageModel>) {
        super();

        this.update(data);
    }

    public update(data: Partial<ImageModel>): void {
        super.update(data);
    }
    /*
     * Client-Side properties (Not from API)
     */
}
