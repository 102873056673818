import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      "data": null,
      "success": true,
      "errors": []
    }
 */
export default class BlogModel extends BaseModel {

    public readonly title: string = '';
    public readonly slug: string = '';
    public readonly description: string = '';
    public readonly created_at: string = '';
    public readonly thumbnail_image = {
        url: '',
    };
    public readonly primary_image = {
        url: '',
    };
    public readonly secondary_image = {
        url: ''
    };
    public readonly copy: string = '';

    public readonly read_more = {
        title: '',
        is_internal_link: true,
        link: '',
        icon: {
            url: ''
        }
    }

    constructor(data: Partial<BlogModel>) {
        super();

        this.update(data);
    }

    public update(data: Partial<BlogModel>): void {
        super.update(data);
    }
    /*
     * Client-Side properties (Not from API)
     */

}
