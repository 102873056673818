import React, { Component, useRef } from 'react';
import './PageWrapper.scss';
import PropTypes from 'prop-types';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { gsap } from "gsap";

class PageWrapperContainer extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        prevProps.location.pathname !== this.props.location.pathname ? window.scrollTo(0, 0) : null;
    }

    scrollToTop() {
        gsap.registerPlugin(ScrollToPlugin);

        gsap.to(this.myRef.current, {
            duration: 1.5,
            scrollTo: { y: 0, offsetY: 0 }
        });
    }

    render() {


        return <div className={'page'} ref={this.myRef}>{this.props.children}</div>;
    }
}

PageWrapperContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    })
}

export default PageWrapperContainer;
