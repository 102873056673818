import React, {Component} from 'react';
import CalendlyView from "./CalendlyView";

class CalendlyContainer extends Component {
    render() {
        return (
            <CalendlyView />
        );
    }
}

export default CalendlyContainer;
