import React, {useEffect, useMemo, useRef} from 'react';
import './Footer.scss';
import {Link} from 'react-router-dom';
import {SocialIcon} from 'react-social-icons';
import {gsap} from "gsap";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";

type IProps = {
    data: {
        first_name: {
            value: string;
            error: string
        },
        email: {
            value: string;
            error: string;
        }
    };
    handleChange: () => void,
    handleSubmit: (event: React.MouseEvent) => void,
    isError: boolean,
    show_message: boolean,
}

const FooterView = (props: IProps) => {
    gsap.registerPlugin(DrawSVGPlugin);

    // TL config
    const circleConfig: GSAPTimelineVars = {repeat: -1};
    const circleTl = useMemo(() => gsap.timeline(circleConfig), []);

    // Refs
    const formRef = useRef<HTMLFormElement>(null);
    const circleRef = useRef<any>(null);
    const messageRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        circleTl.fromTo(
            circleRef.current,
            {
                drawSVG: '0%',
                stroke: '#29B6F6'
            },
            {
                drawSVG: '100%',
                immediateRender: false,
                ease: 'Power1.easeInOut',
                rotation: 360,
                transformOrigin: '50% 50%',
                stroke: '#29B6F6',
                duration: 1,
            }
        ).fromTo(
            circleRef.current,
            {drawSVG: '100%'},
            {
                drawSVG: '0%',
                immediateRender: false,
                ease: 'Power1.easeInOut',
                rotation: -720,
                transformOrigin: '50% 50%',
                stroke: '#FF4081',
                duration: 1.25,
            }
        ).fromTo(
            circleRef.current,
            {drawSVG: '100%'},
            {
                drawSVG: '0%',
                immediateRender: false,
                ease: 'Power1.easeInOut',
                rotation: -720,
                transformOrigin: '50% 50%',
                stroke: '#3b9662',
                duration: 1.25,
            }
        ).fromTo(
            circleRef.current,
            {drawSVG: '0%'},
            {
                drawSVG: '100%',
                immediateRender: false,
                ease: 'Power1.easeInOut',
                rotation: 360,
                transformOrigin: '50% 50%',
                stroke: '#29B6F6',
                duration: 1,
            }
        );

    }, []);

    useEffect(() => {
        if (props.show_message) {
            gsap.to(messageRef.current, {
                autoAlpha: 1,
                color: props.show_message && !props.isError ? '#3b9662' : '#DE0000'
            });

            gsap.to(circleRef.current, {
                autoAlpha: 0
            });
        }
    }, [props.show_message]);

    function submit(event: React.MouseEvent) {
        event.preventDefault();
        gsap.to(circleRef.current, {
            autoAlpha: 1,
        });
        props.handleSubmit(event);
    }

    return (
        <div className={'footer-wrapper'}>
            <div className={'top pl-3 pr-3 pt-5 pb-3 bg-vm-green'}/>

            <div className={'flex flex-col bg-gray flex-col align-items-center'}>
                <div className={'bottom flex justify-content-around align-items-center'}>
                    <div className={'social flex flex-col p-2 align-items-center'}>
                        <div className={'mb-3'}>
                            <SocialIcon className={'mr-2'} url={'https://www.facebook.com/VimaxMedia/'} target="_blank"
                                        rel="noopener noreferrer"/>
                            <SocialIcon className={'mr-2'} url={'https://www.instagram.com/vimaxmedia/?hl=en'}
                                        target="_blank" rel="noopener noreferrer"/>
                        </div>
                        <div className={''}>
                            <SocialIcon className={'mr-2'} url={'https://www.youtube.com/c/Vimaxmedia'} target="_blank"
                                        rel="noopener noreferrer"/>
                            <SocialIcon className={'mr-2'} url={'https://www.tiktok.com/@vimaxmedia'} target="_blank" rel="noopener noreferrer"/>
                            {/*<SocialIcon className={'mr-2'} url={'https://www.linkedin.com/company/vimax-media'}*/}
                            {/*            target="_blank" rel="noopener noreferrer"/>*/}
                        </div>
                    </div>

                    <div className={'subscribe-text flex flex-col'}>
                        <h5 className={'m-0 vm-green'}><strong>Follow & Subscribe</strong></h5>
                        <p className={'mt-2'}>Want the latest foodie resources? We got you covered – sign up now!</p>
                    </div>

                    <div className={'flex flex-col'}>
                        <form className={'input flex items-center'} ref={formRef}>
                            <input type={'text'}
                                   className={'mt-3 lg:mt-0 pl-2 gray bg-white'}
                                   value={props.data.first_name.value}
                                   placeholder={'First Name'}
                                   name={'first_name'}
                                   onChange={props.handleChange}
                            />

                            {props.data.first_name.error.length > 1 ?
                                <label
                                    className={'error mt-3 lg:mt-0 ml-2'}>{props.data.first_name.error}</label> : null}

                            <input type={'text'}
                                   className={'my-3 lg:my-0 lg:ml-3 pl-2 gray bg-white'}
                                   value={props.data.email.value}
                                   placeholder={'Email Address'}
                                   name={'email'}
                                   onChange={props.handleChange}
                            />

                            {props.data.email.error.length > 1 ?
                                <label className={'error ml-2 mb-3 lg:mb-0'}>{props.data.email.error}</label> : null}

                            <button className={'lg:ml-3 bg-vm-green border-none white'}
                                    onClick={(event) => submit(event)}>Submit
                            </button>
                            <svg version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="30px" height="30px"
                                 viewBox="0 0 30 30" className={'ml-3 opacity-0'}>
                                <circle ref={circleRef} fill="none" cx="15" cy="15" r="12.5" strokeWidth={2}/>
                            </svg>
                        </form>
                        <p ref={messageRef} className={'opacity-0 text-base mt-2'}>{ props.show_message && !props.isError ? 'You have successfully subscribed!' : 'There was an issue processing your request' }</p>
                    </div>
                </div>
                <div className={'links pb-5'}>
                    <Link className={'gray'} to={'/privacy-policy'}>Privacy Policy</Link>
                </div>
            </div>
        </div>
    );
};

export default FooterView;
