import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      "data": null,
      "success": true,
      "errors": []
    }
 */
export default class TrendReportModel extends BaseModel {

    public readonly issue: string = '';
    public readonly report: {
        url: string;
    } = { url: '' };
    public readonly id: number = 0;

    constructor(data: Partial<TrendReportModel>) {
        super();

        this.update(data);
    }

    public update(data: Partial<TrendReportModel>): void {
        super.update(data);
    }
    /*
     * Client-Side properties (Not from API)
     */
}
