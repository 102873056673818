import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FooterView from './FooterView';
import SubscribeBarAction from '../../../stores/subscribe-bar/SubscribeBarAction';
import { connect } from 'react-redux';
import { selectRequesting } from '../../../selectors/requesting/RequestingSelector';
import FormUtility from '../../../utilities/FormUtility';
import {hasErrors, selectErrorText} from "../../../selectors/error/ErrorSelector";

class FooterContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            first_name: {
                value: '',
                error: '',
            },
            email: {
                value: '',
                error: '',
            },
            submitting: false,
            show_message: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.submitting === true && this.props.isRequesting === false) {
            this.setState({
                ...this.state,
                submitting: false,
                show_message: true
            });
        }
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: {
                value: event.target.value,
                error: '',
            },
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const name = this.state.first_name.value;
        const email = this.state.email.value;

        const validate = {
            name: await this.validateName(name),
            email: await this.validateEmail(email),
        };

        if (!validate.name || !validate.email ) return;

        this.props.dispatch(
            SubscribeBarAction.handleSubmit({
                first_name: name,
                last_name: '',
                email: email,
            })
        );

        this.setState({
            ...this.state,
            submitting: true,
            first_name: {
                value: '',
                error: '',
            },
            email: {
                value: '',
                error: '',
            },
        });
    }

    validateName(name) {
        if (name.length < 1) {
            this.setState({
                ...this.state,
                first_name: {
                    ...this.state.first_name,
                    error: 'Name is required',
                },
            });

            return false;
        }

        return true;
    }

    validateEmail(email) {
        if (!FormUtility.validateEmail(email)) {
            this.setState({
                ...this.state,
                email: {
                    ...this.state.email,
                    error: 'Valid email address is required',
                },
            });

            return false;
        }

        return true;
    }

    closeMessage() {
        this.setState({
            ...this.state,
            show_message: false,
        });
    }

    render() {
        return (
            <FooterView
                data={this.state}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                closeMessage={this.closeMessage}
                isError={this.props.isError}
                show_message={this.state.show_message}
            />
        );
    }
}

FooterContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    isRequesting: selectRequesting(state, [SubscribeBarAction.REQUEST_EMAIL_SUBSCRIPTION]),
    isError: hasErrors(state, [SubscribeBarAction.REQUEST_EMAIL_SUBSCRIPTION_FINISHED]),
});

export { FooterContainer as Unconnected };
export default connect(mapStateToProps)(FooterContainer);
