import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      "data": null,
      "success": true,
      "errors": []
    }
 */
export default class EmployeeModel extends BaseModel {

    public readonly first_name: string = '';
    public readonly last_name: string = '';
    public readonly job_title: string = '';
    public readonly primary_image = {
        url: '',
    };
    public readonly secondary_image = {
        url: '',
    };
    public readonly order: number = 99;

    constructor(data: Partial<EmployeeModel>) {
        super();

        this.update(data);
    }

    public update(data: Partial<EmployeeModel>): void {
        super.update(data);
    }
    /*
     * Client-Side properties (Not from API)
     */
}
