import { BaseModel } from 'sjs-base-model';

export default class VideoModel extends BaseModel {

    public readonly video: { url: string } = { url: ''};
    public readonly cover_image: { url: string } = { url: '' };
    public readonly order: number = 0;

    constructor(data: Partial<VideoModel>) {
        super();

        this.update(data);
    }

    public update(data: Partial<VideoModel>): void {
        super.update(data);
    }
    /*
     * Client-Side properties (Not from API)
     */
}
