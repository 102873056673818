import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import TrendReportModel from './models/trend/TrendReportModel';

export default class TrendsEffect {
    public static async requestReports(): Promise<TrendReportModel | HttpErrorResponseModel> {
        const endpoint: string = environment.api.strapi.replace(':endpoint', 'trend-reports');

        return EffectUtility.getToModel(TrendReportModel, endpoint);
    }
}
