import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import HomeModel from './models/home/HomeModel';

export default class HomeEffect {
    public static async requestHome(): Promise<HomeModel | HttpErrorResponseModel> {
        const endpoint: string = environment.api.strapi.replace(':endpoint', 'home');

        return EffectUtility.getToModel(HomeModel, endpoint);
    }
}
