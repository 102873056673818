import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavView from './NavView';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class NavContainer extends Component {
    render() {
        return (
            <React.Fragment>
                <NavView transparent={this.props.transparent}/>
            </React.Fragment>
        );
    }
}

NavContainer.propTypes = {
    width: PropTypes.number,
    transparent: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
});

export default withRouter(connect(mapStateToProps, null)(NavContainer));
