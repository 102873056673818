import ActionUtility from '../../utilities/ActionUtility';
import { Dispatch, Store} from 'redux';
import TrendsEffect from './TrendsEffect';

export default class TrendsAction {
    public static readonly REQUEST_TRENDS_REPORTS: string = 'TrendsAction.REQUEST_TRENDS_REPORTS';
    public static readonly REQUEST_TRENDS_REPORTS_FINISHED: string = 'TrendsAction.REQUEST_TRENDS_REPORTS_FINISHED';

    public static requestTrends(): any {
        return async (dispatch: Dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, TrendsAction.REQUEST_TRENDS_REPORTS, TrendsEffect.requestReports);
        };
    }
}
