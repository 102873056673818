import BaseReducer from '../../utilities/BaseReducer';
import IAction from '../../models/IAction';
import InitialImageGalleryState from './models/InitialImageGalleryState';
import ImageModel from './models/image/ImageModel';
import ImageGalleryAction from './ImageGalleryAction';

export default class VideoGalleryReducer extends BaseReducer {
    public readonly initialState: InitialImageGalleryState = {
        records: [],
    };

    public [ImageGalleryAction.REQUEST_IMAGES_FINISHED](state: InitialImageGalleryState, action: IAction<ImageModel>): InitialImageGalleryState {
        return {
            ...state,
            records: action.payload!
        };
    }
}
