import ActionUtility from '../../utilities/ActionUtility';
import { Dispatch, Store} from 'redux';
import ImageGalleryEffect from './ImageGalleryEffect';

export default class ImageGalleryAction {
    public static readonly REQUEST_IMAGES: string = 'ImageGalleryAction.REQUEST_IMAGES';
    public static readonly REQUEST_IMAGES_FINISHED: string = 'ImageGalleryAction.REQUEST_IMAGES_FINISHED';

    public static requestImages(): any {
        return async (dispatch: Dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, ImageGalleryAction.REQUEST_IMAGES, ImageGalleryEffect.requestImages);
        };
    }
}
