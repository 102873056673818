import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import SubscribeBarResponseModel from './models/SubscribeBarResponseModel';

export default class SubscribeBarEffect {
    static async handleSubmit(data) {
        const endpoint = environment.api.subscribe;

        const request = {
            email: data.email,
            first_name: data.first_name,
            organization_id: '6054fec14685c893d8abba763b33706e',
        };

        return EffectUtility.postToModel(SubscribeBarResponseModel, endpoint, request);
    }
}
