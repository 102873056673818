import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      "data": null,
      "success": true,
      "errors": []
    }
 */
export default class HomeModel extends BaseModel {
    public readonly banner_desktop = {
        url: '',
    };
    public readonly banner_mobile = {
        url: '',
    };

    constructor(data: Partial<HomeModel>) {
        super();

        this.update(data);
    }

    public update(data: Partial<HomeModel>): void {
        super.update(data);
    }
    /*
     * Client-Side properties (Not from API)
     */
}
