import ActionUtility from '../../utilities/ActionUtility';
import { Dispatch, Store} from 'redux';
import VideoGalleryEffect from './VideoGalleryEffect';

export default class VideoGalleryAction {
    public static readonly REQUEST_VIDEOS: string = 'VideoGalleryAction.REQUEST_VIDEOS';
    public static readonly REQUEST_VIDEOS_FINISHED: string = 'VideoGalleryAction.REQUEST_VIDEOS_FINISHED';

    public static requestVideos(): any {
        return async (dispatch: Dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, VideoGalleryAction.REQUEST_VIDEOS, VideoGalleryEffect.requestVideos);
        };
    }
}
