import React, { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import PropTypes from 'prop-types';
import './Hamburger.scss';

export interface hamburgerProps {
    open: boolean,
    width: string,
    onClick: () => void,
    color: string,
    activeColor: string,
}

const HamburgerView = (props: hamburgerProps) => {

    const upper = useRef(null);
    const middle = useRef(null);
    const lower = useRef(null);

    const tlConfig: GSAPTimelineVars = { paused: true, reversed: true };
    const timeline: GSAPTimeline = gsap.timeline(tlConfig);
    const [tl] = useState(timeline);

    useEffect(() => {
        tl
            .to(upper.current, {
                    duration: 0.25,
                    attr: { d: 'M8,2 L2,8' },
                    x: 1,
                    ease: 'Power2.easeInOut',
                    stroke: `${props.activeColor}`,
                },
                'start')
            .to(middle.current, {
                    duration: 0.25,
                    autoAlpha: 0,
                    stroke: `${props.activeColor}`,
                },
                'start')
            .to(lower.current, {
                    duration: 0.25,
                    attr: { d: 'M8,8 L2,2' },
                    x: 1,
                    ease: 'Power2.easeInOut',
                    stroke: `${props.activeColor}`,
                },
                'start');

    }, [props.activeColor]);

    useEffect(() => {
        props.open ? tl.play() : tl.reverse();
    })

    return (
        <div className={'hamburger ml-4'} onClick={props.onClick}
        style={{width: props.width }}>
            <svg viewBox="0 0 12 10">
                <path ref={upper} d="M10,2 L2,2"
                      style={{ fill: 'none', stroke: props.color, strokeLinecap: 'round' }} />
                <path ref={middle} d="M2,5 L10,5"
                      style={{ fill: 'none', stroke: props.color, strokeLinecap: 'round' }} />
                <path ref={lower} d="M10,8 L2,8"
                      style={{ fill: 'none', stroke: props.color, strokeLinecap: 'round' }} />
            </svg>
        </div>
    );
};

HamburgerView.propTypes = {
    open: PropTypes.bool.isRequired,
    width: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    activeColor: PropTypes.string.isRequired,
}

export default HamburgerView;
