import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import EmployeeModel from './models/employee/EmployeeModel';

export default class TeamEffect {
    public static async requestTeam(): Promise<EmployeeModel | HttpErrorResponseModel> {
        const endpoint: string = environment.api.strapi.replace(':endpoint', 'employees');

        return EffectUtility.getToModel(EmployeeModel, endpoint);
    }
}
