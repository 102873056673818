import ActionUtility from '../../utilities/ActionUtility';
import SubscribeBarEffect from './SubscribeBarEffect';

export default class SubscribeBarAction {
    static REQUEST_EMAIL_SUBSCRIPTION = 'SubscribeBarAction.REQUEST_EMAIL_SUBSCRIPTION';
    static REQUEST_EMAIL_SUBSCRIPTION_FINISHED = 'SubscribeBarAction.REQUEST_EMAIL_SUBSCRIPTION_FINISHED';

    static CLOSE_MESSAGE_WINDOW = 'SubscribeBarAction.CLOSE_MESSAGE_WINDOW';

    static handleSubmit(data) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, SubscribeBarAction.REQUEST_EMAIL_SUBSCRIPTION, SubscribeBarEffect.handleSubmit, data);
        };
    }

    static closeMessageWindow() {
        return ActionUtility.createAction(SubscribeBarAction.CLOSE_MESSAGE_WINDOW);
    }
}
